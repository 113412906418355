import { AddHighlightTypes } from './utils.types';

const addHighlightBold = (string: AddHighlightTypes) => {
  const regexHighlightBold = string?.replace(
    /\[(.*?)\]/g,
    '<span style="font-weight: 500;"}>$1</span>'
  );
  return regexHighlightBold;
};

export default addHighlightBold;
