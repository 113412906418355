import React from 'react';
import SectionHeader from '@src/components/Shared/SectionHeader';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import Container from '@src/components/Shared/Container';
import RenderButtons from '@src/components/Shared/RenderButtons';
import { CallToActionPropTypes } from './CallToAction.types';
const CallToAction: React.FC<CallToActionPropTypes> = ({
  attr,
  id,
  title,
  bodyCopy,
  buttons,
  className
}) => {
  return <Container attr={attr} id={id} className='md:lg-[0] !max-w-[118.4rem] rounded-[2.2rem] bg-background-tertiary px-[2rem] py-[4rem] text-center md:px-[3rem] md:pb-[3.5rem] md:pt-[5.1rem]' data-sentry-element="Container" data-sentry-component="CallToAction" data-sentry-source-file="CallToAction.tsx">
      <SectionHeader title={title} titleClassName='text-body-copy--dark font-normal leading-[2.2rem] !font-[200] text-[2.2rem] md:text-[2.8rem] lg:text-[4.8rem] mb-[3.2rem]' align='center' data-sentry-element="SectionHeader" data-sentry-source-file="CallToAction.tsx" />
      <RenderBodyCopy className={`${className} mx-auto mb-[2.2rem] max-w-[82rem] text-left text-[1.6rem] font-[200] md:mb-[2.4rem] md:text-center lg:mb-[3.2rem] lg:text-[2.4rem]`} bodyCopy={bodyCopy} data-sentry-element="RenderBodyCopy" data-sentry-source-file="CallToAction.tsx" />
      <RenderButtons className='justify-left md:justify-center' items={buttons} data-sentry-element="RenderButtons" data-sentry-source-file="CallToAction.tsx" />
    </Container>;
};
export default CallToAction;